import "./burpexample.css"
import { useState } from "react";

function BurpExample() {
    const [selectedAttack, setSelectedAttack] = useState(null);

    const attacks = [
        {
            title: "SQL Injection",
            steps: [
                "Intercept the login request using Burp Proxy.",
                "Send the request to Repeater for manual testing.",
                "Modify the request by injecting ' OR 1=1 -- in the login field.",
                "Send the modified request and check if authentication is bypassed."
            ]
        },
        {
            title: "Cross-Site Scripting (XSS)",
            steps: [
                "Find an input field that reflects user input.",
                "Inject <script>alert('XSS')</script> and submit.",
                "If an alert pops up, the site is vulnerable to XSS.",
                "Use Burp Intruder to automate payload injection."
            ]
        },
        {
            title: "Intercept & Modify Requests",
            steps: [
                "Enable Intercept in the Proxy tab.",
                "Navigate to a login or form submission page.",
                "Modify the captured request, e.g., change user role parameters.",
                "Forward the modified request and check the response."
            ]
        }
    ];

    return (
        <div className="burp-container">
            <h1 className="burp-title">Burp Suite Pentesting</h1>
            <div className="burp-grid">
                {attacks.map((attack, index) => (
                    <div key={index} className="burp-card" onClick={() => setSelectedAttack(attack)}>
                        <h2>{attack.title}</h2>
                    </div>
                ))}
            </div>

            {selectedAttack && (
                <div className="burp-steps">
                    <h2>Steps for {selectedAttack.title}</h2>
                    <ul>
                        {selectedAttack.steps.map((step, idx) => (
                            <li key={idx}>{step}</li>
                        ))}
                    </ul>
                    <button className="close-btn" onClick={() => setSelectedAttack(null)}>Close</button>
                </div>
            )}
        </div>
    );
}

export default BurpExample;
