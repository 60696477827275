import "./burpsuite.css"

function BurpSuite() {
    const burpCommands = [
        {
            title: "Burp Proxy",
            commands: [
                { name: "Intercept Requests", description: "Capture and modify HTTP requests.", sample: "Enable Intercept in Proxy tab." },
                { name: "Forward Requests", description: "Send captured requests to the server.", sample: "Click Forward in Proxy tab." },
                { name: "Drop Requests", description: "Discard captured requests.", sample: "Click Drop in Proxy tab." }
            ]
        },
        {
            title: "Burp Repeater",
            commands: [
                { name: "Modify Requests", description: "Manually edit and resend requests.", sample: "Right-click request > Send to Repeater." },
                { name: "Send Requests", description: "Submit modified requests.", sample: "Click Send in Repeater tab." }
            ]
        },
        {
            title: "Burp Intruder",
            commands: [
                { name: "Brute Force Attack", description: "Automate input variations for testing.", sample: "Configure attack type and payloads." },
                { name: "SQL Injection", description: "Test SQL vulnerabilities.", sample: "Use ' OR 1=1 -- in Intruder payloads." }
            ]
        },
        {
            title: "Burp Scanner",
            commands: [
                { name: "Passive Scanning", description: "Identify security issues without modifying requests.", sample: "Enable passive scanning in settings." },
                { name: "Active Scanning", description: "Automated testing for vulnerabilities.", sample: "Start scan in Scanner tab." }
            ]
        }
    ];

    return (
        <div className="container">
            <h1 className="title">Pentesting with Burp Suite</h1>
            {burpCommands.map((category, index) => (
                <div key={index} className="category">
                    <h2 className="category-title">{category.title}</h2>
                    <div className="grid-container">
                        {category.commands.map((cmd, idx) => (
                            <div key={idx} className="grid-item">
                                <h3>{cmd.name}</h3>
                                <p>{cmd.description}</p>
                                <code className="sample">{cmd.sample}</code>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default BurpSuite;
