import { useState } from "react";
import "./burpsuite.css"

function BurpSuiteGuide() {
  const steps = [
    {
      title: "Step 1: Set Up Burp Suite",
      content: "Open Burp Suite, configure your browser to use Burp Proxy, and install the Burp CA certificate for HTTPS interception."
    },
    {
      title: "Step 2: Intercept Requests Using Burp Proxy",
      content: "Enable Intercept in the Proxy tab, visit the target website, and modify or forward captured requests."
    },
    {
      title: "Step 3: Modify Requests with Burp Repeater",
      content: "Right-click a request in Proxy, send it to Repeater, modify parameters, and resend the request to analyze responses."
    },
    {
      title: "Step 4: Perform Attacks Using Burp Intruder",
      content: "Send a request to Intruder, configure attack types, set payloads (like SQL Injection or Brute Force), and start the attack."
    },
    {
      title: "Step 5: Scan for Vulnerabilities with Burp Scanner",
      content: "Right-click a request, select ‘Active Scan’, and analyze the results for security weaknesses."
    },
    {
      title: "Step 6: Exploit and Document Findings",
      content: "Use Burp tools to further exploit vulnerabilities and document findings with detailed reports."
    }
  ];

  const [currentStep, setCurrentStep] = useState(0);

  return (
    <div className="container">
      <h1 className="title">Pentesting with Burp Suite</h1>
      <div className="step">
        <h2>{steps[currentStep].title}</h2>
        <p>{steps[currentStep].content}</p>
      </div>
      <div className="controls">
        <button
          onClick={() => setCurrentStep(currentStep - 1)}
          disabled={currentStep === 0}
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentStep(currentStep + 1)}
          disabled={currentStep === steps.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default BurpSuiteGuide;
