
import BurpSuite from "./burpsuite";
import BurpSuiteGuide from "./burpsuiteguide";
import BurpExample from "./burpexample";
function App() {
  return (
    <div className="App">
      <BurpSuite/>
      <BurpSuiteGuide/>
      <BurpExample/>
    </div>
  );
}

export default App;
